@import '~mdb5/src/mdb/scss/mdb.free.scss';

// MDB PRO
@import '~mdb5/src/mdb/scss/pro/variables';
@import '~mdb5/src/mdb/scss/pro/modal';
@import '~mdb5/src/mdb/scss/pro/perfect-scrollbar';
@import '~mdb5/src/mdb/scss/pro/sidenav';
@import '~mdb5/src/mdb/scss/pro/animate';
//@import '~mdb5/src/mdb/scss/pro/lightbox';
//@import '~mdb5/src/mdb/scss/pro/rating';
//@import '~mdb5/src/mdb/scss/pro/timepicker';
@import '~mdb5/src/mdb/scss/pro/navbar';
//@import '~mdb5/src/mdb/scss/pro/datepicker';
//@import '~mdb5/src/mdb/scss/pro/popconfirm';
//@import '~mdb5/src/mdb/scss/pro/datatable';
//@import '~mdb5/src/mdb/scss/pro/steps';
//@import '~mdb5/src/mdb/scss/pro/stepper';
//@import '~mdb5/src/mdb/scss/pro/timeline';
//@import '~mdb5/src/mdb/scss/pro/sticky';
@import '~mdb5/src/mdb/scss/pro/select';
//@import '~mdb5/src/mdb/scss/pro/loading';
//@import '~mdb5/src/mdb/scss/pro/autocomplete';
@import '~mdb5/src/mdb/scss/pro/theme/theming';
//@import '~mdb5/src/mdb/scss/pro/chips';
//@import '~mdb5/src/mdb/scss/pro/multi-range';
//@import '~mdb5/src/mdb/scss/pro/date-time-picker';

@import '~datatables.net-bs5/css/dataTables.bootstrap5.min.css';

@import "litepicker/dist/css/litepicker";